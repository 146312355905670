export const colors = {
	black: '#1d1f1e',
	white: '#FFF',
	paleGrey: '#e1e8e4',
	lightGrey: '#c6ccc8',
	grey: '#9ba09d',
	medGrey: '#787e7a',
	darkGrey: '#4f5652',
	midnightGrey: '#323634',
	red: '#dc3300',
	paleGreen: '#dbe8dd',
	lightGreen: '#B1D2B8',
	green: '#7da886',
	brightGreen: '#68b678',
	blueGreen: '#66c9a4',
}

export const sizes = {
	headerHeight: 70,
	headerHeightMobile: 70,

	tablet: '767px',
	sdesk: '1112px',
	ldesk: '1500px',

	gutterMobile: '16px',
	gutterFluid: '10vw',

	baseFont: '15px',
	deskBaseFont: '15px',
}

export const fonts = {
	title: 'Noto Sans KR',
	gen: 'Noto Sans KR',
}

export const times = {
	short: 200,
	med: 400,
	long: 600,

	ease: 'ease-in-out',
}

export const mixins = {
	skin: `
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.black};
    opacity: .5;
  `,

	gradVert: (color, colorBtm) => `
    background: -moz-linear-gradient(top,  ${color} 0%, ${colorBtm} 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  ${color} 0%,${colorBtm} 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  ${color} 0%,${colorBtm} 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  `,
}
