import React from 'react'

import F from './Footer.view'

const Footer = props => {
	return (
		<F className='main-footer' {...props}>
			<div className='copyright'>copyright © madze creations llc 2020</div>
		</F>
	)
}
export default Footer
