import React from 'react'
import Link from 'gatsby-plugin-transition-link'
import PropTypes from 'prop-types'

import H from './Header.view'

const Header = ({ children, siteTitle, ...props }) => {
	return (
		<H className='main-header'>
			<h1>
				<Link to='/'>{siteTitle}</Link>
			</h1>
			{children}
		</H>
	)
}
Header.propTypes = {
	siteTitle: PropTypes.string.isRequired,
}
export default Header
