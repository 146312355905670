import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import GlobalStyles from '../styles/global-styles'

import Header from './common/Header'
import Nav from './common/Nav'
import Footer from './common/Footer'

const Wrap = styled.div`
	min-height: 100vh;
`

const navItems = [
	{
		txt: 'me',
		to: '/me',
	},
	{
		txt: 'contact',
		to: '/contact',
	},
	{
		txt: 'web',
		to: '/web',
	},
	{
		txt: 'misc.',
		to: '/misc',
	},
]

const SiteLayout = ({ children, path, ...props }) => {
	const data = useStaticQuery(graphql`
		query TitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			<GlobalStyles />
			<Wrap>
				<Header siteTitle={data.site.siteMetadata.title} />
				<section className='content-section'>
					<Nav navItems={navItems} isLanding={path === '/landing/'} />
					{children}
				</section>
				<Footer />
			</Wrap>
		</>
	)
}

export default SiteLayout
