import { css } from 'styled-components'

import notoLight from './fonts/NotoSansKR-Light.otf'
import notoReg from './fonts/NotoSansKR-Regular.otf'
import notoBlack from './fonts/NotoSansKR-Black.otf'

const fonts = css`
	@font-face {
		font-family: 'Noto Sans KR';
		src: url(${notoLight}) format('opentype');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'Noto Sans KR';
		src: url(${notoReg}) format('opentype');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Noto Sans KR';
		src: url(${notoBlack}) format('opentype');
		font-weight: 900;
		font-style: normal;
	}
`

export default fonts
