module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/Site.layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"madze-creations","short_name":"mc-site","start_url":"/","background_color":"#dbe8dd","theme_color":"#7da886","display":"minimal-ui","icon":"src/images/mc-logo-500.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
