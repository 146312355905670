import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Wrap from './Nav.view'
import Branding from '../Branding'
import NavItem from './Nav.item'

import { times } from '../../../styles'

const transitionLength = times.med * 0.001

const MainNav = ({ navItems, isLanding, ...props }) => {
	const [isOpen, setIsOpen] = useState(false)

	const transitionOptions = {
		exit: {
			length: transitionLength + 0.001,
			zIndex: 1,
			trigger: () => setIsOpen(false),
		},
		entry: {
			length: 0.001,
			zIndex: 2,
		},
	}

	const LogoClickHandler = () => setIsOpen(state => !state)
	return (
		<Wrap className='main-nav' isOpen={isOpen} isLanding={isLanding} {...props}>
			<div className='logo-btn' onClick={() => LogoClickHandler()}>
				<span className='text'>menu...</span>
				<Branding />
			</div>
			<nav>
				{navItems &&
					navItems.map(item => (
						<NavItem key={item.txt} {...item} transitionOptions={transitionOptions} />
					))}
			</nav>
		</Wrap>
	)
}
MainNav.propTypes = {
	navItems: PropTypes.arrayOf(
		PropTypes.shape({
			txt: PropTypes.string.isRequired,
		})
	),
}
export default MainNav
