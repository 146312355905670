// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-me-js": () => import("./../src/pages/me.js" /* webpackChunkName: "component---src-pages-me-js" */),
  "component---src-pages-misc-js": () => import("./../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-web-js": () => import("./../src/pages/web.js" /* webpackChunkName: "component---src-pages-web-js" */)
}

