import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

const NavItem = ({ txt, to, isActive, transitionOptions }) => {
	return (
		<li>
			<TransitionLink
				className={isActive ? 'nav-link isActive' : 'nav-link'}
				to={to}
				{...transitionOptions}
			>
				{txt}
			</TransitionLink>
		</li>
	)
}
NavItem.propTypes = {
	txt: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	transitionOptions: PropTypes.shape({
		exit: PropTypes.shape({}),
		entry: PropTypes.shape({}),
	}),
}
export default NavItem
