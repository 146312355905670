import styled from 'styled-components'
import { colors, times, sizes } from '../../../styles'
export default styled.div`
	position: fixed;
	top: 50%;
	left: 0;
	transition: all ${times.short}ms ${times.ease};
	${props =>
		!props.isOpen && !props.isLanding
			? `
      transform: translate3d(-50%,-50%,0) scale(0.5);
    `
			: 'transform: translate3d(0, -50%,0);'}
	height: 33vh;
	width: auto;
	z-index: 100;
	@media only screen and (min-width: ${sizes.tablet}) {
		left: ${props => (props.isLanding ? '10vw' : 0)};
	}
	@media only screen and (min-width: ${sizes.sdesk}) {
		left: ${props => (props.isLanding ? '20vw' : 0)};
	}
	.logo-btn {
		z-index: 1;
		height: 100%;
		background: ${colors.white};
		&:hover {
			.text {
				opacity: 1;
			}
		}
		.text {
			position: absolute;
			display: none;
			top: 50%;
			left: 20%;
			color: ${colors.lightGrey};
			transition: opacity ${times.med}ms ${times.ease};
			@media only screen and (min-width: ${sizes.sdesk}) {
				display: block;
				opacity: ${props => (props.isOpen || !props.isLanding ? '0 !important' : 0)};
			}
		}
	}
	.branding {
		height: 100%;
		fill: ${props => (props.isOpen ? colors.lightGreen : colors.paleGreen)};
		transition: transform ${times.short}ms ${times.ease}, fill ${times.short}ms ${times.ease};
		@media only screen and (min-width: ${sizes.sdesk}) {
			&:hover {
				fill: ${props => (props.isOpen ? colors.paleGreen : colors.blueGreen)};
				cursor: pointer;
			}
		}
		${props =>
			props.isOpen
				? `
      transform: rotateZ(15deg);
    `
				: ''};
	}
	nav {
		z-index: 0;
		position: absolute;
		top: 0;
		right: 16px;
		width: 100%;
		height: 100%;
		text-align: right;
		list-style: none;
		li {
			position: absolute;
			transform-origin: bottom left;
			a {
				color: ${colors.grey};
				transition: all ${times.short}ms ${times.ease};
				@media only screen and (min-width: ${sizes.sdesk}) {
					transform: translate3d(0, 0, 0);
					display: block;
					&:hover {
						color: ${colors.blueGreen};
						transform: translate3d(-0.5em, 0, 0);
					}
				}
			}
			&:first-child {
				transition: transform ${times.short * 0.8}ms ${times.ease};

				transform: translate3d(0, 200%, 0) rotateZ(-90deg);
				top: 0;
				left: 40%;

				${props => (props.isOpen ? `transform: translate3d(150%, -150%, 0) rotateZ(-50deg)` : '')};
			}
			&:nth-child(2) {
				transition: transform ${times.short * 0.8}ms ${times.ease} ${times.short * 0.4}ms;

				transform: translate3d(0, 300%, 0) rotateZ(-90deg);
				top: 0;
				left: 50%;

				${props => (props.isOpen ? `transform: translate3d(150%, -150%, 0) rotateZ(-35deg)` : '')};
			}
			&:nth-child(3) {
				transition: transform ${times.short * 0.8}ms ${times.ease} ${times.short * 0.8}ms;

				transform: translate3d(0, 0, 0) rotateZ(-45deg);
				top: 40%;
				right: 0;

				${props => (props.isOpen ? `transform: translate3d(250%, 90%, 0) rotateZ(10deg)` : '')};
			}
			&:nth-child(4) {
				transition: transform ${times.short * 0.8}ms ${times.ease} ${times.short * 1.3}ms;

				transform: translate3d(0, 0, 0) rotateZ(-45deg);
				top: 70%;
				right: 0;

				${props => (props.isOpen ? `transform: translate3d(200%, 100%, 0) rotateZ(30deg)` : '')};
			}
			${props =>
				!props.isOpen
					? `
        transition: transform ${times.short * 0.7}ms ${times.ease} !important;
      `
					: ''}
		}
	}
`
