import styled from 'styled-components'
import { sizes, colors } from '../../../styles'
export default styled.header`
	display: flex;
	align-items: center;
	height: ${sizes.headerHeightMobile}px;
	@media only screen and (min-width: ${sizes.sdesk}) {
		height: ${sizes.headerHeight}px;
	}
	h1 {
		padding-left: ${sizes.gutterMobile};
		font-size: 1rem;
		color: ${colors.lightGrey};
		a {
			color: inherit;
		}
		@media only screen and (min-width: ${sizes.tablet}) {
			padding-left: ${sizes.gutterFluid};
		}
	}
`
