/*
 =================================================
  GLOBAL STYLES

  These will be applied accross the entire app.
  
  NOTE: These should be used sparingly (think of them more as resets or 
    defaults for batches of elements like e.g., all heading tags)
 =================================================
* */

import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import fontFaces from './fonts'

import { sizes, colors, times, fonts } from './vars'

const GlobalStyles = createGlobalStyle`
  ${reset}
  ${fontFaces}

  body {
    font-size: ${sizes.baseFont};
    font-family: ${fonts.gen};
    position:relative;
    width: 100%;
    color: ${colors.medGrey};
    @media only screen and (min-width: ${sizes.sdesk} ) {
      font-size: ${sizes.deskBaseFont};
    }
  }
  div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    font-size: inherit;
  }
  div, p, main, aside, header, footer, blockquote, table, ul, section, legend, input, textarea, select, button, a {
    position:relative;
    box-sizing: border-box;
  }
  p, span, blockquote, li, a, em, i {
    line-height: 1.3;
  }
  strong {
    font-weight: 600;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: ${fonts.title};
  }
  h3,h5 {
    font-weight: 600;
  }
  h2,h4,h6 {
    font-weight: 400;
    text-transform: uppercase;
  }
  h1 {
    font-size: 3rem;
    @media only screen and (min-width: ${sizes.tablet} ) {
      font-size: 4rem;
    }
  }
  h2 {
    font-size: 2rem;
    @media only screen and (min-width: ${sizes.tablet} ) {
      font-size: 3rem
    }
  }

  p {
    padding: 1rem 0;
  }

  button {
    padding:0;
    margin:0;
    border: none;
    display: block;
    outline: none;
  }
  a {
    color: ${colors.green};
    text-decoration: none;
    transition: color ${times.short}ms ${times.ease};
    &:hover {
      color: ${colors.blueGreen};
    }
  }
  .rt-content {
    h1,h2,h3,h4,h5,h6,p {
      margin: 1em 0;
    }
    ul {
      margin-left: 2em;
      list-style: circle;
    }
    a {
      overflow-wrap: break-word;
    }
  }
  .flex {
    @media only screen and (min-width: ${sizes.tablet} ) {
      display:flex;
      &.stretch {
        justify-content: stretch;
      }
      &.center {
        justify-content: center;
      }
      &.center-align {
        align-items: center;
      }
      &.middle {
        justify-content: center;
        align-items: center;
      }
    }
  }
  .flex-col {
    flex-grow: 1;
  }
`
export default GlobalStyles
