import React from 'react'
import Link from 'gatsby-plugin-transition-link'
import styled from 'styled-components'

import Logo from '../../images/mc-logo'

const Wrap = styled.div`
	position: relative;
	color: inherit;
	fill: inherit;
	svg {
		color: inherit;
		fill: inherit;
		height: 100%;
	}
`
const Branding = ({ isHomeBtn, ...props }) => (
	<Wrap className='branding' {...props}>
		{isHomeBtn ? (
			<Link to='/'>
				<Logo />
			</Link>
		) : (
			<Logo />
		)}
	</Wrap>
)
export default Branding
